import { ParentProps, JSX } from "solid-js"
import { IoClose } from "solid-icons/io"

import "../assets/scss/components/modal.scss"

type ModalProps = ParentProps<{
  title?: string
  ref: HTMLDialogElement
}> &
  JSX.HTMLAttributes<HTMLElement>

const Modal = (props: ModalProps) => {
  return (
    <dialog ref={props.ref}>
      <div class="primary-modal">
        <div class="modal-content">
          <div class="modal-header">
            <h2>{props.title}</h2>
            <form method="dialog">
              <button
                class="inactive"
                onmouseenter={(e: MouseEvent) =>
                  ((e.currentTarget as HTMLElement).style.fill = "white")
                }
                onmouseleave={(e: MouseEvent) =>
                  ((e.currentTarget as HTMLElement).style.fill = "black")
                }
              >
                <IoClose />
              </button>
            </form>
          </div>
          {props.children}
        </div>
      </div>
    </dialog>
  )
}

export default Modal
