import _ from "lodash"
import { createMemo } from "solid-js"

import { Store } from "./types"
import {
  compareLanes,
  compareRides,
  convertDate,
  getDiffs,
  getRide,
} from "./utils"

const memos = (state: Store) => {
  const getFlights = createMemo(() =>
    Object.values(state.flights).sort(compareRides)
  )

  const getDeadheads = createMemo(() =>
    Object.values(state.deadheads).sort(compareRides)
  )

  const getPairings = createMemo(() => Object.values(state.pairings))

  const getCodes = createMemo(() =>
    Array.from(
      new Set(
        getFlights().flatMap(({ Flight }) => [
          Flight["Airport Departure"],
          Flight["Airport Arrival"],
        ])
      )
    ).sort()
  )

  const getRides = createMemo(() =>
    [Object.values(state.flights), Object.values(state.deadheads)]
      .flat()
      .map(getRide)
  )

  const getStartDate = createMemo(() => {
    const earliestFlight = _.minBy(getRides(), "Time Departure")
    const date = earliestFlight
      ? convertDate(earliestFlight["Time Departure"])
      : new Date()
    date.setUTCHours(0)
    date.setUTCMinutes(0)
    date.setUTCMilliseconds(0)
    return date
  })

  const getEndDate = createMemo(() => {
    const latestFlight = _.maxBy(getRides(), "Time Arrival")
    return latestFlight ? convertDate(latestFlight["Time Arrival"]) : new Date()
  })

  const getNumberCol = createMemo(() => {
    let { daysDiff } = getDiffs(getStartDate(), getEndDate())
    return (daysDiff + 1) * 24
  })

  const getDays = createMemo(() => {
    let daysOfTheMonth = []
    let loop = new Date(getStartDate())

    while (loop <= getEndDate()) {
      daysOfTheMonth.push(loop.toLocaleDateString("fr-FR"))
      loop = new Date(loop)
      loop.setUTCDate(loop.getDate() + 1)
    }

    return daysOfTheMonth
  })

  const getDaysRef = createMemo(() => new Array(getDays().length))

  const getLanes = createMemo(() =>
    _.keys(_.groupBy(getRides(), "Lane")).sort(compareLanes)
  )

  const getAirportsDeparture = createMemo(() =>
    _.keys(_.groupBy(getRides(), "Airport Departure")).sort()
  )

  const getAirportsArrival = createMemo(() =>
    _.keys(_.groupBy(getRides(), "Airport Arrival")).sort()
  )

  const getBalanceAirports = createMemo(() =>
    Array.from(new Set(getFlights().flatMap(({ _issues }) => _issues))).sort()
  )

  return {
    getAirportsArrival,
    getAirportsDeparture,
    getBalanceAirports,
    getCodes,
    getDays,
    getDaysRef,
    getDeadheads,
    getEndDate,
    getFlights,
    getLanes,
    getNumberCol,
    getPairings,
    getRides,
    getStartDate,
  }
}

export default memos
