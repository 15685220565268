import { Show } from "solid-js"
import { useParams } from "@solidjs/router"

import store from "../store"
import { connect } from "../websocket"
import "../assets/scss/components/connect.scss"

const STATUTLOOKUP = {
  CLOSED: {
    icon: "❓",
    text: "Connection lost",
  },
  CONNECTING: {
    icon: "🔌",
    text: "Connecting",
  },
  ERROR: {
    icon: "☠️",
    text: "Connection error",
  },
  LOADING: {
    icon: "⏳",
    text: "Loading",
  },
  READY: {
    icon: "🌐",
    text: "Connected",
  },
}

const Connect = (props: { toggle: boolean }) => {
  const { state } = store
  const taskId = useParams().id
  const getIcon = () => STATUTLOOKUP[state.webSocketStatus].icon
  const getStatus = () => STATUTLOOKUP[state.webSocketStatus].text

  const isWebSocketClosed = () =>
    ["CLOSED", "ERROR"].includes(state.webSocketStatus)

  const onClick = () => {
    if (isWebSocketClosed()) {
      connect(taskId)
    }
  }

  const getClass = () => `${props.toggle ? "close" : ""} ${"foo-status"}`

  const getStyle = () => ({
    cursor: isWebSocketClosed() ? "pointer" : "default",
  })

  return (
    <div class="connect">
      <span class={getClass()} style={getStyle()} onClick={onClick}>
        <div>{getIcon()}</div>
        <Show when={!props.toggle}>
          <h2 class="online-text">{getStatus()}</h2>
        </Show>
      </span>
    </div>
  )
}

export default Connect
