import { ParentProps, createSignal, Show, JSX, splitProps } from "solid-js"

type ButtonProps = ParentProps<{
  iconDark?: string
  iconLight?: string
  active?: boolean
  toggle?: boolean
  disabled?: boolean
}> &
  JSX.ButtonHTMLAttributes<HTMLButtonElement>

const Button = (props: ButtonProps) => {
  const [isHover, setIsHovered] = createSignal(false)

  const [local, buttonProps] = splitProps(props, [
    "iconDark",
    "iconLight",
    "active",
    "toggle",
    "disabled",
    "children",
  ])

  const iconSrc = () => {
    return local.active || isHover() ? local.iconLight : local.iconDark
  }

  return (
    <button
      classList={{ close: local.toggle }}
      onMouseEnter={() => (local.disabled ? null : setIsHovered(true))}
      onMouseLeave={() => (local.disabled ? null : setIsHovered(false))}
      {...buttonProps}
      disabled={local.disabled}
    >
      <img src={iconSrc()} />
      <Show when={!local.toggle}>{local.children}</Show>
    </button>
  )
}

export default Button
