import { batch, createRoot } from "solid-js"
import { createStore, produce } from "solid-js/store"

import memos from "./memos"
import type {
  AddPairingAction,
  DeadheadBase,
  DeletePairingAction,
  Flight,
  FlightBase,
  Pairing,
  Store,
} from "./types"
import {
  compareRides,
  formatDate,
  getRideKey,
  getRideObj,
  scrollTo,
} from "./utils"
import { sendWS } from "./websocket"

const store = () => {
  const getInitialState = (): Store => ({
    deadheads: {},
    flights: {},
    kpi: {},
    logicMode: false,
    nightstops: {},
    pairings: {},
    selectedFlights: [],
    solverStatus: "READY",
    webSocket: undefined,
    webSocketStatus: "CLOSED",
    zoom: 0.8,
  })

  const [state, setState] = createStore(getInitialState())

  const resetState = () => {
    state.webSocket?.close()
    setState(getInitialState())
  }

  const addFlight = (obj: FlightBase, fixed: boolean, pairing?: Pairing) => {
    let key = getRideKey(obj)

    setState(
      produce(state => {
        state.flights[key] ??= {
          ...obj,
          _fixed: fixed,
          _highlight: true,
          _isoline: false,
          _issues: [],
          _key: key,
          _pairing: pairing,
          _show: true,
        }

        state.flights[key]["_pairing"] ??= pairing
      })
    )

    return key
  }

  const addDeadhead = (obj: DeadheadBase, pairing?: Pairing) => {
    let key = getRideKey(obj)

    setState(
      produce(state => {
        state.deadheads[key] ??= {
          ...obj,
          _highlight: true,
          _pairing: pairing,
          _key: key,
        }

        state.deadheads[key]["_pairing"] ??= pairing
      })
    )

    return key
  }

  const getPairingKey = (pairing: Pairing) => {
    return pairing.Pairing[0].Duty[0]._key
  }

  const addPairing = (obj: Pairing): AddPairingAction => {
    const { Pairing, ...rest } = obj

    const pairing: Pairing = {
      Pairing: [],
      ...rest,
    }

    Pairing.forEach(({ Duty, ...rest }) =>
      pairing.Pairing.push({
        Duty: Duty.map(obj => {
          if ("Flight" in obj) {
            return state.flights[addFlight(obj, false, pairing)]
          } else {
            return state.deadheads[addDeadhead(obj, pairing)]
          }
        }),
        ...rest,
      })
    )

    setState(
      produce(state => (state.pairings[getPairingKey(pairing)] = pairing))
    )

    return { type: "ADD_PAIRING", pairing }
  }

  const deletePairing = (pairing: Pairing): DeletePairingAction => {
    setState(
      produce(state => {
        pairing.Pairing.forEach(duty => {
          duty.Duty.forEach(obj => {
            if ("Flight" in obj) {
              const flight = state.flights[getRideKey(obj)]
              flight._pairing = undefined
              flight._pairingRef = undefined
            }
          })
        })

        delete state.pairings[getPairingKey(pairing)]
      })
    )

    return { type: "DELETE_PAIRING", pairing }
  }

  const toggleFlight = (flight: Flight) => {
    const index = state.selectedFlights.indexOf(flight)

    setState(
      produce(state => {
        if (index !== -1) {
          state.selectedFlights.splice(index, 1)
        } else if (!flight._highlight) {
          state.selectedFlights = [flight]
        } else {
          state.selectedFlights.push(flight)
          state.selectedFlights.sort(compareRides)
        }

        if (state.logicMode) {
          const empty = state.selectedFlights.length === 0

          Object.values(state.flights).forEach(f => {
            f._disjointReason = undefined
            f._highlight = empty
          })

          state.selectedFlights.forEach(f => (f._highlight = true))
        }

        state.selectedPairing = undefined
      })
    )

    if (state.selectedFlights.length > 0) {
      const keys = JSON.stringify(state.selectedFlights.map(getRideObj))
      sendWS("GET_PAIRING", keys)

      if (state.logicMode) {
        sendWS("GET_SUCC", keys)
        sendWS("GET_PRED", keys)
      }
    }

    return index === -1
  }

  const resetLogicMode = () =>
    setState(
      produce(state =>
        Object.values(state.flights).forEach(f => {
          f._disjointReason = undefined
          f._highlight = true
        })
      )
    )

  const disableLogicMode = () => {
    setState(
      produce(state => {
        state.logicMode = false
        resetLogicMode()
      })
    )
  }

  const deselectAll = () => {
    setState(
      produce(state => {
        state.selectedFlights = []
        state.selectedPairing = undefined
        resetLogicMode()
      })
    )
  }

  const setNightstop = (day: string, airport: string, amount: number) => {
    setState(
      "nightstops",
      produce(nightstops => {
        const date = formatDate(day)
        if (!nightstops[date]) nightstops[date] = {}
        if (!nightstops[date][airport]) nightstops[date][airport] = {}
        nightstops[date][airport]["expected"] = amount
      })
    )
  }

  const getNightstop = (day: string) => {
    let nightstops = state.nightstops[formatDate(day)]
    return nightstops ? Object.entries(nightstops) : []
  }

  const loadScheduleFile = async (file: File) => {
    const parsed = JSON.parse(await file.text())

    batch(() => {
      resetState()

      if ("Flight" in parsed[0]) {
        parsed.forEach((obj: FlightBase) => addFlight(obj, false))
      } else {
        parsed.forEach(addPairing)
      }
    })
  }

  const centerOn = (flight: Flight = state.selectedFlights[0]) => {
    if (!flight) {
      return
    }

    for (const [paneRef, flightRef] of [
      [state.schedulePaneRef, flight._scheduleRef],
      [state.pairingPaneRef, flight._pairingRef],
    ]) {
      if (paneRef && flightRef) {
        scrollTo(paneRef, flightRef)
      }
    }
  }

  const browseTo = (flights: Flight[]) => {
    setState(
      produce(state => {
        state.selectedFlights = [...flights.slice(0, -1)]

        if (flights.length > 0) {
          toggleFlight(flights[flights.length - 1])
          centerOn(flights[0])
        } else {
          deselectAll()
        }
      })
    )
  }

  return {
    addDeadhead,
    addFlight,
    addPairing,
    browseTo,
    centerOn,
    deletePairing,
    deselectAll,
    disableLogicMode,
    getNightstop,
    loadScheduleFile,
    resetLogicMode,
    resetState,
    setNightstop,
    setState,
    state,
    toggleFlight,
    ...memos(state),
  }
}

export default createRoot(store)
