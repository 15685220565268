export const LoaderCard = () => {
  return (
    <div class="loader">
      <div class="wrapper">
        <div class="square"></div>
        <div class="content__line">
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>
    </div>
  )
}

export const LoaderTask = () => {
  return (
    <div class="loadertask">
      <div class="content__header">
        <div class="title"></div>
        <div class="option"></div>
      </div>
      <div class="content__description">
        <div class="description"></div>
        <div class="description"></div>
        <div class="description"></div>
      </div>
      <div class="content__info">
        <div class="info"></div>
        <div class="info"></div>
      </div>
    </div>
  )
}

export const LoaderBanner = () => {
  return (
    <div class="loader" style="background-color: #f8fafb">
      <div class="wrapper">
        <div class="line"></div>
      </div>
    </div>
  )
}

export const LoaderScreen = () => {
  return (
    <section class="check">
      <svg viewBox="25 25 50 50">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
    </section>
  )
}
