import store from "./store"
import type {
  Deadhead,
  DeadheadBase,
  Flight,
  FlightBase,
  Pairing,
  Ride,
  RideBase,
} from "./types"

export const convertDate = (s: string) => {
  return new Date(s.replace(" ", "T") + "Z")
}

export const getDiffs = (start: Date, end: Date) => {
  const millisecDiff = end.getTime() - start.getTime()

  const daysDiff = Math.trunc(millisecDiff / (1000 * 60 * 60 * 24))
  const dayRem = millisecDiff % (1000 * 60 * 60 * 24)

  const hoursDiff = Math.trunc(dayRem / (1000 * 60 * 60))
  const hoursRem = dayRem % (1000 * 60 * 60)

  const minutesDiff = Math.trunc(hoursRem / (1000 * 60))

  return { daysDiff, hoursDiff, minutesDiff }
}

export const getOffset = (start: Date, end: Date) => {
  const { daysDiff, hoursDiff, minutesDiff } = getDiffs(start, end)

  return daysDiff * 24 * 60 + hoursDiff * 60 + minutesDiff
}

export const getColumn = (t: Date) => {
  const { getStartDate } = store
  const { daysDiff, hoursDiff } = getDiffs(getStartDate(), t)
  return daysDiff * 24 + hoursDiff + 2
}

export const compareLanes = (a: string, b: string) => {
  if (/^\d+$/.test(a) && /^\d+$/.test(b)) {
    return parseInt(a) - parseInt(b)
  } else {
    return a.localeCompare(b)
  }
}

export const compareRides = (a: Flight | Deadhead, b: Flight | Deadhead) => {
  const rideA = getRide(a)
  const rideB = getRide(b)

  const timeDiff =
    Date.parse(rideA["Time Departure"]) - Date.parse(rideB["Time Departure"])
  return timeDiff ? timeDiff : compareLanes(rideA.Lane, rideB.Lane)
}

export const getRide = (obj: Flight | Deadhead) => {
  if ("Flight" in obj) {
    return obj["Flight"]
  } else {
    return obj["Deadhead"]
  }
}

export const getRideKey = (obj: FlightBase | DeadheadBase) => {
  let type: string
  let ride: RideBase

  if ("Deadhead" in obj) {
    type = "Deadhead"
    ride = obj["Deadhead"]
  } else {
    type = "Flight"
    ride = obj["Flight"]
  }

  return JSON.stringify({
    [type]: {
      ID: ride.ID,
      "Airport Departure": ride["Airport Departure"],
      "Airport Arrival": ride["Airport Arrival"],
      "Time Departure": ride["Time Departure"],
      "Time Arrival": ride["Time Arrival"],
      Lane: ride.Lane,
    },
  })
}

type ReduceApi<T extends Ride> = T extends Flight ? FlightBase : DeadheadBase

export const getRideObj = <T extends Ride>(value: T): ReduceApi<T> => {
  let type: "Flight" | "Deadhead"
  let ride: RideBase

  if ("Deadhead" in value) {
    type = "Deadhead"
    ride = value["Deadhead"]
  } else {
    type = "Flight"
    ride = value["Flight"]
  }

  return { [type]: ride } as ReduceApi<T>
}

export const getPairingObj = (obj: Pairing) => {
  return {
    Pairing: obj.Pairing.map(duty => {
      return {
        Duty: duty.Duty.map(getRideObj),
        Cost: duty["Cost"],
        "Duty Length": duty["Duty Length"],
        "Flight Duty Length": duty["Flight Duty Length"],
        "Standard Flight Duty Limit": duty["Standard Flight Duty Limit"],
        "Max Flight Duty Limit": duty["Max Flight Duty Limit"],
      }
    }),
    "Total Cost": obj["Total Cost"],
    "Credited Hours": obj["Credited Hours"],
    "Engagement Length": obj["Engagement Length"],
    Rests: obj["Rests"],
  }
}

export const scrollTo = (
  paneRef: HTMLDivElement,
  flightRef: HTMLDivElement
) => {
  const flightRect = flightRef.getBoundingClientRect()
  const paneRect = paneRef.getBoundingClientRect()
  const timelineRect = paneRef.firstElementChild!.getBoundingClientRect()
  const offsetLeft = paneRef.scrollLeft + flightRect.left - paneRect.left
  const offsetTop = paneRef.scrollTop + flightRect.top - paneRect.top

  paneRef.scrollTo({
    behavior: "smooth",
    left: offsetLeft - (paneRect.width - flightRect.width) / 2,
    top:
      offsetTop -
      (paneRect.height - flightRect.height + timelineRect.height) / 2,
  })
}

export const formatDate = (date: string) =>
  date.replace("\r", "").split("/").reverse().join("-")
