import { batch } from "solid-js"
import { produce } from "solid-js/store"

import store from "./store"
import { FlightBase, Pairing } from "./types"
import { getRideKey, getRideObj } from "./utils"

export const connect = (taskId: string) => {
  const { addPairing, setState, state } = store

  const setError = (objs: FlightBase[], error: string) => {
    setState(
      produce(state => {
        objs.forEach((obj: FlightBase) => {
          state.flights[getRideKey(obj)]._error = error
        })
      })
    )
  }

  const setBalance = (balance: any) => {
    setState(
      produce(state => {
        balance.issues.forEach((obj: FlightBase) => {
          state.flights[getRideKey(obj)]._issues.push(balance.airport)
        })
      })
    )
  }

  setState("webSocketStatus", "CONNECTING")
  const url = `${location.protocol.replace("http", "ws")}//${
    location.host
  }/backend/ws/program_checker/${taskId}/`
  const ws = new WebSocket(url)
  setState("webSocket", ws)

  ws.onopen = () => {
    reloadChecker()
    setState("webSocketStatus", "READY")
  }

  ws.onerror = () => {
    setState("webSocketStatus", "ERROR")
  }

  ws.onclose = () => {
    setState("webSocketStatus", "CLOSED")
  }

  ws.onmessage = event => {
    const msg = JSON.parse(event.data)
    const tokens = msg.cmd.split(" ")

    switch (tokens[0]) {
      case "START":
        setState("webSocketStatus", "LOADING")
        break

      case "END":
        if (tokens[1] === "SOLVE_SCHEDULE") {
          setState("solverStatus", "READY")
        }

        setState("webSocketStatus", "READY")
        break

      case "CHECK_SCHEDULE":
        batch(() => {
          for (const key of ["NO_PAIR", "NO_PRED", "NO_SUCC"]) {
            setError(msg.payload[key], key)
          }

          for (const balance of msg.payload["BALANCES"]) {
            setBalance(balance)
          }
        })

        break

      case "SOLVE_SCHEDULE": {
        let {
          error,
          new_pairings,
        }: { error: string; new_pairings: Pairing[] } = msg.payload

        if (error) {
          setState("solverStatus", "ERROR")
        } else {
          batch(() => new_pairings.forEach(obj => addPairing(obj)))
        }
        break
      }

      case "GET_NO_PAIR":
      case "GET_NO_PRED":
      case "GET_NO_SUCC":
        setError(msg.payload, msg.cmd)
        break

      case "COMPUTE_BALANCE":
        for (let i = 0; i < parseInt(msg.payload); i++) {
          sendWS("GET_BALANCE", i)
        }

        break

      case "GET_BALANCE":
        setBalance(msg.payload)
        break

      case "GET_PRED":
      case "GET_SUCC": {
        const flights = JSON.parse(msg.args[0])
        const receivedKey = flights.map(getRideKey).join("->")
        const selectedKey = state.selectedFlights
          .map(getRideObj)
          .map(getRideKey)
          .join("->")

        if (receivedKey === selectedKey) {
          setState(
            produce(state => {
              msg.payload[0].forEach((obj: FlightBase) => {
                state.flights[getRideKey(obj)]._highlight = true
              })

              msg.payload[1].forEach(([obj, reason]: [FlightBase, string]) => {
                state.flights[getRideKey(obj)]._disjointReason = reason
              })
            })
          )
        }
        break
      }

      case "GET_KPI": {
        let { Nightstops, ...rest } = msg.payload["GET_KPI"]
        setState(
          produce(state => {
            Object.keys(Nightstops).forEach(airport => {
              Object.keys(Nightstops[airport]).forEach(date => {
                if (!state.nightstops[date]) state.nightstops[date] = {}
                if (!state.nightstops[date][airport])
                  state.nightstops[date][airport] = {}
                state.nightstops[date][airport]["value"] =
                  Nightstops[airport][date]
              })
            })
            state.kpi = rest
          })
        )
        break
      }

      case "GET_PAIRING": {
        const flights = JSON.parse(msg.args[0])
        const receivedKey = flights.map(getRideKey).join("->")
        const selectedKey = state.selectedFlights
          .map(getRideObj)
          .map(getRideKey)
          .join("->")

        setState(
          produce(state => {
            state.selectedPairing =
              receivedKey === selectedKey ? msg.payload : undefined
          })
        )
        break
      }
    }
  }
}

export const sendWS = (cmd: string, ...args: any[]) => {
  const { state } = store
  let tries = 0

  const poll = (resolve: Function) => {
    if (state.webSocket?.readyState === WebSocket.OPEN) {
      resolve()
    } else if (tries++ < 10) {
      setTimeout(() => poll(resolve), 100)
    }
  }

  new Promise(poll).then(() =>
    state.webSocket?.send(JSON.stringify({ cmd, args }))
  )
}

export const reloadChecker = () => {
  const { getFlights } = store
  const fixed = getFlights().filter(f => f._fixed)
  sendWS("RUN_CHECKER", getFlights().map(getRideObj))
  sendWS("SET_FIX", JSON.stringify(fixed.map(getRideObj)))
}
