import { Router } from "@solidjs/router"
import AppRoutes from "./router"

import "./assets/scss/styles.scss"

const App = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  )
}

export default App
