import { Match, Show, Switch, batch, createResource, lazy } from "solid-js"
import { Navigate, Outlet, Route, Routes, useParams } from "@solidjs/router"

import { getSession, getTask } from "./axios"
import store from "./store"
import { Task } from "./types"
import { connect } from "./websocket"
import { LoaderScreen } from "./components/Loader"
import { toggle } from "./components/Sidebar"
import Sidebar from "./components/Sidebar"
import "./assets/scss/pages/layout.scss"

const Login = lazy(() => import("./views/Login"))
const TaskMenu = lazy(() => import("./views/TaskMenu"))
const Upload = lazy(() => import("./views/Upload"))
const Settings = lazy(() => import("./views/Settings"))
const KPI = lazy(() => import("./views/KPI"))
const Overview = lazy(() => import("./views/Overview"))
const Logout = lazy(() => import("./views/Logout"))
const NotFound = lazy(() => import("./views/NotFound"))

const LoginWrapper = () => {
  const [data] = createResource(async () => {
    const session = await getSession()
    return session?.isAuthenticated
  })

  return (
    <Switch fallback={<Navigate href="/login" />}>
      <Match when={data.loading}>
        <LoaderScreen />
      </Match>

      <Match when={data()}>
        <Outlet />
      </Match>
    </Switch>
  )
}

const TaskWrapper = () => {
  const {
    addDeadhead,
    addFlight,
    addPairing,
    getFlights,
    resetState,
    setState,
  } = store

  const taskId = useParams().id

  const [data] = createResource(async () => {
    const task: Task = await getTask(taskId)

    if (task === undefined) {
      return false
    }

    resetState()
    connect(taskId)

    batch(() => {
      task.flights.forEach(obj => addFlight(obj, obj._fixed))
      task.deadheads.forEach(obj => addDeadhead(obj))
      task.pairings.forEach(obj => addPairing(obj))
      setState("nightstops", task.nightstops)
    })

    return true
  })

  return (
    <Switch fallback={<NotFound />}>
      <Match when={data.loading}>
        <LoaderScreen />
      </Match>

      <Match when={data()}>
        <Show when={getFlights().length === 0}>
          <Navigate href={`/tasks/${taskId}/upload`} />
        </Show>

        <section class={toggle() ? "layout close" : "layout"}>
          <Sidebar />
          <Outlet />
        </section>
      </Match>
    </Switch>
  )
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" component={Login} />

      <Route path="" component={LoginWrapper}>
        <Route path="/" component={TaskMenu} />

        <Route path="/tasks/:id" component={TaskWrapper}>
          <Route path="/upload" component={Upload} />
          <Route path="/settings" component={Settings} />
          <Route path="/kpi" component={KPI} />
          <Route path="/overview" component={Overview} />
        </Route>

        <Route path="/logout" component={Logout} />
        <Route path="*" component={NotFound} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
