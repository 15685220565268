import axios, { AxiosResponse } from "axios"

import store from "./store"
import { Deadhead, Flight, Pairing } from "./types"
import { getPairingObj, getRideObj } from "./utils"

const { getCodes } = store

axios.defaults.withCredentials = true
axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.xsrfHeaderName = "X-CSRFToken"

const BACKEND = axios.create({
  withCredentials: true,
  baseURL: "/backend/",
})

const wrapError = async (f: () => Promise<AxiosResponse>) => {
  try {
    return (await f()).data
  } catch (error) {
    console.error("An error occurred:", error)
  }
}

export const getAirports = async (id: string) =>
  await wrapError(
    async () =>
      await BACKEND.post(`tasks/${id}/airports`, { codes: getCodes() })
  )

export const updateAirports = async (
  id: string,
  code: string,
  field: string,
  value: any
) =>
  await wrapError(
    async () =>
      await BACKEND.patch(`tasks/${id}/airports`, { code, field, value })
  )

export const getSettings = async (id: string) =>
  await wrapError(async () => await BACKEND.post(`tasks/${id}/settings`))

export const updateSettings = async (id: string, settings: any) =>
  await wrapError(
    async () => await BACKEND.patch(`tasks/${id}/settings`, { settings })
  )

export const getTasks = async () =>
  await wrapError(async () => await BACKEND.get("tasks"))

export const createTask = async (description: string) =>
  await wrapError(async () => await BACKEND.post("tasks", { description }))

export const getTask = async (id: string) =>
  await wrapError(async () => await BACKEND.get(`tasks/${id}`))

export const updateTask = async (
  id: string,
  fields: {
    description?: string
    flights?: Flight[]
    deadheads?: Deadhead[]
    pairings?: Pairing[]
    nightstops?: Record<string, Record<string, Record<string, number>>>
  }
) =>
  await wrapError(
    async () =>
      await BACKEND.patch(`tasks/${id}`, {
        description: fields.description,
        flights: fields.flights?.map(flight =>
          Object.assign(getRideObj(flight), { _fixed: flight._fixed })
        ),
        deadheads: fields.deadheads?.map(getRideObj),
        pairings: fields.pairings?.map(getPairingObj),
        nightstops: fields.nightstops,
      })
  )

export const deleteTask = async (id: string) =>
  await wrapError(async () => await BACKEND.delete(`tasks/${id}`))

export const cloneTask = async (id: string, description: string) =>
  await wrapError(
    async () => await BACKEND.post(`tasks/${id}/clone`, { description })
  )

export const getSession = async () =>
  await wrapError(async () => await BACKEND.get("authentication/session"))

export const login = async (username: string, password: string) => {
  try {
    return (await BACKEND.post("login", { username, password })).status
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.status
    }

    console.error("An error occurred:", error)
  }
}

export const logout = async () => {
  try {
    return (await BACKEND.post("authentication/logout/")).status
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.status
    }

    console.error("An error occurred:", error)
  }
}
