import { For, Show, createSignal } from "solid-js"
import { A, useLocation, useParams } from "@solidjs/router"

import "../assets/scss/layout/sidebar.scss"

import Connect from "./Connect"
import Button from "./Button"
import store from "../store"
import Modal from "./Modal"

interface Link {
  name: string
  href: string
  iconLight: string
  iconDark: string
  disabled: Function
}

export const [toggle, setToggle] = createSignal(false)

const Sidebar = () => {
  const { getFlights, getPairings, state } = store
  const taskId = useParams().id

  const links: Link[] = [
    {
      name: "Tasks",
      href: "/",
      iconLight: "/svg/icons/light-task-icon.svg",
      iconDark: "/svg/icons/dark-task-icon.svg",
      disabled: () => false,
    },
    {
      name: "Upload",
      href: `/tasks/${taskId}/upload`,
      iconLight: "/svg/icons/light-upload-icon.svg",
      iconDark: "/svg/icons/dark-upload-icon.svg",
      disabled: () => false,
    },
    {
      name: "Timeline",
      href: `/tasks/${taskId}/overview`,
      iconLight: "/svg/icons/light-viewer-icon.svg",
      iconDark: "/svg/icons/dark-viewer-icon.svg",
      disabled: () => getFlights().length === 0,
    },
  ]

  const infos: Link[] = [
    {
      name: "KPI",
      href: `/tasks/${taskId}/kpi`,
      iconLight: "/svg/icons/light-kpi-icon.svg",
      iconDark: "/svg/icons/dark-kpi-icon.svg",
      disabled: () =>
        getPairings().length === 0 && Object.keys(state.kpi).length === 0,
    },
    {
      name: "Settings",
      href: `/tasks/${taskId}/settings`,
      iconLight: "/svg/icons/light-settings-icon.svg",
      iconDark: "/svg/icons/dark-settings-icon.svg",
      disabled: () => getFlights().length === 0,
    },
    {
      name: "Log out",
      href: "/logout",
      iconLight: "/svg/icons/light-logout-icon.svg",
      iconDark: "/svg/icons/dark-logout-icon.svg",
      disabled: () => false,
    },
  ]

  const location = useLocation()

  let modalRef: HTMLDialogElement

  const handleDefaultClick = () => {
    setToggle(prev => !prev)
  }

  return (
    <aside class={toggle() ? "sidebar close" : "sidebar"}>
      <div
        class={toggle() ? "toggle close" : "toggle"}
        onclick={handleDefaultClick}
      >
        <img src="/svg/icons/arrow-circle-icon.svg" alt="toggle" />
      </div>
      <div class="logo">
        <Show when={!toggle()} fallback={<h1>CO</h1>}>
          <img src="/img/logo-crewopt.svg" alt="crewopt-logo" class="logo" />
        </Show>
      </div>
      <section class={toggle() ? "sidebar-items close" : "sidebar-items"}>
        <For each={links}>
          {link => (
            <A href={link.href} end>
              <Button
                iconDark={link.iconDark}
                iconLight={link.iconLight}
                active={location.pathname === link.href}
                toggle={toggle()}
                disabled={link.disabled()}
              >
                {link.name}
              </Button>
            </A>
          )}
        </For>
      </section>
      <section class={toggle() ? "sidebar-infos close" : "sidebar-infos"}>
        <span class="inactive">
          <Button
            iconDark="/svg/icons/dark-help-icon.svg"
            iconLight="/svg/icons/light-help-icon.svg"
            toggle={toggle()}
            onclick={() => modalRef.showModal()}
          >
            Help
          </Button>
          <Modal ref={modalRef!} title="Help / Keyboard shortcuts">
            <div class="modal-body">
              <h3>GENERAL</h3>
              <p>
                <strong>ESCAPE</strong> : Deselect the active element.
              </p>
              <p>
                <strong>n</strong> : Select the next item.
              </p>
              <p>
                <strong>p</strong> : Select previous item.
              </p>
              <p>
                <strong>f</strong> : Fix selected item(s).
              </p>
              <p>
                <strong>c</strong> : Check flight schedule.
              </p>
              <p>
                <strong>⚠️</strong> : Some flights with the corresponding ID
                have different airport departure and/or arrival
              </p>
            </div>
          </Modal>
        </span>
        <For each={infos}>
          {link => (
            <A href={link.href} end>
              <Button
                iconDark={link.iconDark}
                iconLight={link.iconLight}
                active={location.pathname === link.href}
                toggle={toggle()}
                disabled={link.disabled()}
              >
                {link.name}
              </Button>
            </A>
          )}
        </For>
      </section>
      <section class={toggle() ? "sidebar-foo close" : "sidebar-foo"}>
        <Connect toggle={toggle()} />
      </section>
    </aside>
  )
}

export default Sidebar
